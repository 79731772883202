@font-face
{
font-family: "Montserrat-Light";
font-style:normal;
src:url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}
@font-face
{
font-family: "Montserrat-Regular";
font-style:normal;
src:url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face
{
font-family: "Montserrat-SemiBold";
font-style:normal;
src:url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face
{
  font-family: 'Staff Wide';
font-style:normal;
src: url("./assets/fonts/subset-StaffWide-Black.woff2") format('woff2')
}
@font-face
{
font-family: "montserrat-v26-latin-700";
font-style:normal;
src:url("./assets/fonts/montserrat-v26-latin-500.woff2") format("opentype");
}

@font-face
{
font-family: "FuturaLT-Bold";
font-style:normal;
src:url("./assets/fonts/FuturaLT-Bold.woff2") format("opentype");
}
  @font-face
{
font-family: "FuturaPTLight";
font-style:normal;
src:url("./assets/fonts/FuturaPTLight.woff2") format("opentype");
}
  @font-face
{
font-family: "FuturaPTMedium";
font-style:normal;
src:url("./assets/fonts/FuturaPTMedium.woff2") format("opentype");
}




@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxRSW32.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.offer{
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.offer i{
  color: #fff06b;
  padding: 2px;
}
.offer p{
  font-size: 20px;
   color: #fff;
  text-align: center;
  font-family: "Montserrat-Regular";
  padding: 10px;
  margin-bottom: 0px;
}

.privacy{
  background-color: #000;
}
.privacy a{
  font-size: 16px;
  color: #fff!important;
  text-align: center;
  text-decoration: none;
}

.hero-banner{
  background-image: url("./assets/img/shortlpbanner.png");
  background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 50px;
    background-repeat: no-repeat;
}

.hero-content{
  max-width: 750px;
}
.hero-content h1{
 color: #fff;
 font-size: 55px;
 font-family: 'Staff Wide';
}
.subhead-2 {
  color: #fff;
  font-size: 22px;
  font-family: "Montserrat-Regular";
}

.subhead-3 {
  color: #fff;
  font-size: 22px;
  font-family: "Montserrat-Regular";
}

.btn.hero-btn {

   text-decoration: none;
     font-family: "montserrat-v26-latin-700";
   font-size: 1.2rem;
   line-height: 14px;
   color: #000!important;
   border-radius: 50px;
   background-color: #fff;
   padding: 18px 55px;
   font-weight: bold;
   width: 50%;
     margin: 12px 0 23px;
   padding: 22px 22px;
}

.content-4, ul,li {
  color: #000000;
  font-size: 17px;

font-family: "FuturaPTMedium";
/*font-weight:900;*/
}
.content-5 {
  margin: 0px;
  color: #000000;
  font-size: 17px;
font-family: "JTUSjIg1_i6t8kCHKm459Wlhyw";

  font-weight: 600;
   padding-bottom:10px;
}
.content-7 {
  margin: 0px;
  padding-bottom:10px;
  color: #000000;
  font-size: 32px;
font-family: "FuturaPTMedium";

 
}
.content-6{
  color: #000000;
  font-size: 50px;
  text-align: center;
  font-family: "FuturaLT-Bold";
/*     background-color: #00b2e3;*/
}



@media screen and (max-width:767px){
   
  .hero-content h1{
      font-size: 30px;
      line-height: 42px;
  
  }
  .subhead-2{
      font-size:17px;
  }
  .subhead-3{
       font-size:15px;
  }
  .icon.d-flex{
      display:block!important;
      line-height: 1;
  }
  .btn.hero-btn{
    width: 70%;
  }

   .hero-section {
    background-image: url("./assets/img/shortlpbannermobile.jpg");
}
}